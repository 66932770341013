<template>
  <div class="se-card-box">
    <search-user @submit="submitSearch" />

    <div class="se-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showNewModal"
        >新增
        </a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1500 }"
      row-key="id"
      size="middle"
      @change="sortChange"
    >
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record.id)">编辑</a>
          <a class="se-red-link-color" @click="deleteUser(record.id)">删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      :total-count="pagination.total_count"
      @change="fetchData"
    />

    <!-- 新增 模态框 -->
    <new-user
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑 模态框 -->
    <edit-user
      v-if="isShowEditModal"
      :id="editingId"
      :visible.sync="isShowEditModal"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import { destroyUserByAdmin, findUsersByAdmin, updateUserEffectiveByAdmin } from '@/api/user'
import Pagination from '@/components/Pagination/index'
import SearchUser from '@/views/admin/users/Search'

export default {
  name: 'UserList',
  components: {
    SearchUser,
    Pagination,
    NewUser: () => import('@/views/admin/users/New'),
    EditUser: () => import('@/views/admin/users/Edit')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      editingId: 0, // 正在编辑的记录id
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '登录账号',
          dataIndex: 'username',
          fixed: 'left'
        },
        {
          title: '姓名',
          dataIndex: 'full_name'
        },
        {
          title: '手机号',
          dataIndex: 'phone_number'
        },
        {
          title: '邮箱',
          dataIndex: 'email'
        },
        {
          title: '角色',
          dataIndex: 'role'
        },
        {
          title: '有效性',
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          width: 120,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findUsersByAdmin(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked

      this.$confirm({
        title: effective ? '确定将账号生效吗?' : '确定失效账号吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateUserEffectiveByAdmin(record.id, { effective: effective }).then((res) => {
            vm.editingEffectiveId = 0
            if (res.code === 0) {
              record.effective = checked
            }
          }).catch(() => {
            vm.editingEffectiveId = 0
          })
        }
      })
    },

    deleteUser(id) {
      const vm = this
      this.$confirm({
        title: '确定删除吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          destroyUserByAdmin(id).then((res) => {
            if (res.code === 0) {
              vm.fetchData()
            }
          })
        }
      })
    }
  }
}
</script>
